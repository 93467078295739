import React, { FC, useEffect, useState } from 'react'

import { Cog6ToothIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid'
import { Autocomplete, InputAdornment, TextField, Tooltip } from '@mui/material'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { Draggable } from 'react-beautiful-dnd'
import {
  CreateLearnerCategoryMutation,
  CreateLearnerCategoryMutationVariables,
  CreateLearnerCourseMutation,
  CreateLearnerCourseMutationVariables,
  DeleteLearnerCategoryMutation,
  DeleteLearnerCategoryMutationVariables,
  RankLearnerItemsMutation,
  RankLearnerItemsMutationVariables,
  UpdateLearnerCategoryMutation,
  UpdateLearnerCategoryMutationVariables,
  type LearnerCategory,
  type LearnerCourse,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { apolloCache } from 'src/apolloCache'
import { updateCache } from 'src/apolloCache/functions/updateCache'
import { getOptions } from 'src/components/Learner/Courses/helpers'
import Button from 'src/components/Library/Button/Button'
import DragAndDropFolderList, {
  DragAndDropFolder,
} from 'src/components/Library/DragAndDropFolders/DragAndDropFolderList'
import DraggableFolder from 'src/components/Library/DragAndDropFolders/DraggableFolder'
import { DragHandle as DragHandleComponent } from 'src/components/Library/DragAndDropFolders/DragHandle'
import { getItemStyle } from 'src/components/Library/DragAndDropFolders/utils'
import Empty from 'src/components/Library/Empty/Empty'
import IconButton from 'src/components/Library/IconButton'
import Modal from 'src/components/Modal/Modal'
import LearnerNewCategoryForm from 'src/components/Settings/LearnerCategory/Categories/LearnerNewCategoryForm/LearnerNewCategoryForm'
import {
  QUERY as FIND_LEARNER_CATEGORIES_QUERY,
  LearnerCategoryRefetch,
  LearnerLearnerCategory,
} from 'src/components/Settings/LearnerCategory/LearnerCategoriesCell'
import LearnerCourseForm from 'src/components/Settings/LearnerCourse/LearnerCourseForm'
import {
  CREATE_CATEGORY_MUTATION,
  DELETE_CATEGORY_MUTATION,
  UPDATE_CATEGORY_MUTATION,
} from 'src/lib/queries/learner/learnerCategory'
import { CREATE_COURSE_MUTATION } from 'src/lib/queries/learner/learnerCourse'
import RANK_ITEM_MUTATION from 'src/lib/queries/learner/rank'
import { useAuth } from 'src/Providers'
import { filterCourses, nextRank, published } from 'src/Util'

import CategoryDetails from './CategoryDetails/CategoryDetails'

interface LearnerCategoriesProps {
  categories: LearnerLearnerCategory[]
  refetch: LearnerCategoryRefetch
  isStafflinkUser: boolean
}

const LearnerCategories: FC<LearnerCategoriesProps> = ({
  categories,
  refetch,
  isStafflinkUser,
}) => {
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id
  const [newCourseModalOpen, setNewCourseModalOpen] = useState(false)
  const [newCategoryModalOpen, setNewCategoryModalOpen] = useState(false)
  const [selectedLearnerCategoryId, setSelectedLearnerCategoryId] = useState<
    number | undefined
  >()

  const [selected, setSelected] = useState<LearnerLearnerCategory>(null)
  const [inputSearchValue, setInputSearchValue] = useState('')

  const [searchValue, setSearchValue] = React.useState(null)

  const filteredCategories = filterCourses(categories, inputSearchValue)

  interface Option {
    id: number
    courseId: number
    name: string
    __typename: string
    type: string
  }

  const [rankLearnerItems] = useMutation<
    RankLearnerItemsMutation,
    RankLearnerItemsMutationVariables
  >(RANK_ITEM_MUTATION, {
    onCompleted: () => {
      toast.success('Change Saved')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [createLearnerCategory, { error }] = useMutation<
    CreateLearnerCategoryMutation,
    CreateLearnerCategoryMutationVariables
  >(CREATE_CATEGORY_MUTATION, {
    onCompleted: () => {
      toast.success('New category created')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    update: (_, { data: { createLearnerCategory } }) =>
      updateCache({
        query: FIND_LEARNER_CATEGORIES_QUERY,
        mutationResult: createLearnerCategory,
        updatedField: 'learnerCategories',
      }),
  })

  const [updateLearnerCategory] = useMutation<
    UpdateLearnerCategoryMutation,
    UpdateLearnerCategoryMutationVariables
  >(UPDATE_CATEGORY_MUTATION, {
    onCompleted: () => {
      toast.success('Category updated')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [deleteLearnerCategory] = useMutation<
    DeleteLearnerCategoryMutation,
    DeleteLearnerCategoryMutationVariables
  >(DELETE_CATEGORY_MUTATION, {
    onCompleted: () => {
      setSelected(null)
      toast.success('Category deleted')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    update: (_, { data: { deleteLearnerCategory } }) =>
      updateCache({
        query: FIND_LEARNER_CATEGORIES_QUERY,
        mutationResult: deleteLearnerCategory,
        updatedField: 'learnerCategories',
      }),
  })

  const saveNewCategory = async (input) => {
    const castInput = Object.assign(input, {
      clientId: clientId,
      rank: nextRank(categories),
      status: 'DRAFT',
      isAll: true,
    })
    const { data } = await createLearnerCategory({
      variables: { input: castInput, isStafflinkUser },
    })
    setNewCategoryModalOpen(false)

    return data.createLearnerCategory
  }

  const [createLearnerCourse] = useMutation<
    CreateLearnerCourseMutation,
    CreateLearnerCourseMutationVariables
  >(CREATE_COURSE_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Course created')
      navigate(routes.settingsLearnerCategories())
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const saveNewCourse = (input) => {
    const learnerCategoryId = parseInt(input.learnerCategoryId)
    const category = categories.find(
      (category) => category.id === learnerCategoryId,
    )

    const castInput = Object.assign(input, {
      clientId: clientId,
      rank: nextRank(category?.learnerCourses ? category.learnerCourses : []),
      status: 'DRAFT',
      learnerCategoryId: learnerCategoryId,
    })
    createLearnerCourse({ variables: { input: castInput } })
    setNewCourseModalOpen(false)
  }

  const openNewCourseModal = (categoryId: number) => {
    setSelectedLearnerCategoryId(categoryId)
    setNewCourseModalOpen(true)
  }

  const onCategoryDragEnd = (reorderedCategories: LearnerCategory[]) => {
    apolloCache.writeQuery({
      query: FIND_LEARNER_CATEGORIES_QUERY,
      data: {
        learnerCategories: reorderedCategories,
      },
    })
    // fire and forget to the db
    saveCategoriesRanking(reorderedCategories)
  }

  const saveCategoriesRanking = (categories: LearnerCategory[]) => {
    const reorderedCategoriesInput = categories.map((item, index) => ({
      id: item.id,
      rank: index + 1,
    }))

    rankLearnerItems({
      variables: {
        input: { itemType: 'Category', items: reorderedCategoriesInput },
      },
    })
  }

  const onCourseDragEnd = (reorderedCourses: LearnerCourse[]) => {
    const categoryIndex = categories.findIndex(
      (category) => category.id === reorderedCourses[0].learnerCategoryId,
    )

    const newCategories = [...categories]
    newCategories[categoryIndex].learnerCourses = reorderedCourses

    // since categories has been updated with the new order
    // we can just update the cache now to avoid using state or refetch
    apolloCache.writeQuery({
      query: FIND_LEARNER_CATEGORIES_QUERY,
      data: {
        learnerCategories: newCategories,
      },
    })
    // fire and forget to the db
    saveCoursesRanking(reorderedCourses)
  }

  const saveCoursesRanking = (courses: LearnerCourse[]) => {
    const rankedCoursesInput = courses.map((item, index) => ({
      id: item.id,
      rank: index + 1,
    }))

    rankLearnerItems({
      variables: {
        input: { itemType: 'Course', items: rankedCoursesInput },
      },
    })
  }

  // set autocomplete search options list
  useEffect(() => {
    if (searchValue) {
      if (searchValue.__typename === 'LearnerCategory') {
        setSelected(searchValue)
        setSelectedLearnerCategoryId(searchValue.id)
      } else if (searchValue.__typename === 'LearnerCourse') {
        navigate(routes.editLearnerCourse({ id: searchValue.id }))
      } else {
        navigate(routes.editLearnerCourse({ id: searchValue.courseId }))
      }
    }
  }, [searchValue])

  useEffect(() => {
    // update selected if categories is updated through a cache method or refetch
    if (!selectedLearnerCategoryId) return
    const selectedCategory = categories.find(
      (category) => category.id === selectedLearnerCategoryId,
    )
    setSelected(selectedCategory)
  }, [selectedLearnerCategoryId, categories])

  const options = getOptions(filteredCategories)

  return (
    <>
      <div className="flex h-[calc(100vh-64px)] w-full align-middle">
        <div className="flex h-full w-full flex-col bg-white lg:max-w-[320px] 2xl:max-w-[400px]">
          <header className="px-3 pb-0 pt-4">
            <div className="min-w-0 flex-1">
              <Autocomplete
                sx={{}}
                freeSolo
                disableClearable
                options={options || []}
                id="autocomplete-search"
                size="small"
                groupBy={(option) => option.course?.name}
                getOptionLabel={(option) =>
                  typeof option === 'string'
                    ? option
                    : `${option.type}: ${option.name}`
                }
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.name, inputValue)
                  const parts = parse(option.name, matches)
                  return (
                    <li
                      {...props}
                      key={option.name + option.id}
                      className="mx-2 cursor-pointer rounded p-1 pl-4 hover:bg-gray-50"
                    >
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  )
                }}
                onChange={(event, newValue) => {
                  const wasEnterPressed =
                    event &&
                    event.nativeEvent instanceof KeyboardEvent &&
                    event.nativeEvent.key === 'Enter'
                  if (wasEnterPressed) {
                    return // Do not trigger onChange logic if Enter is pressed
                  }

                  // Only set searchValue if an option was selected
                  if (typeof newValue === 'object' && newValue !== null) {
                    setSearchValue(newValue)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    sx={{ backgroundColor: '#FFF' }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <MagnifyingGlassIcon className="-mr-2 h-5 w-5 text-gray-500" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </header>
          <main className="flex-grow overflow-y-auto p-3">
            <div className="justify-items-center">
              <div className="m-auto mt-1">
                {categories?.length > 0 && (
                  <DragAndDropFolderList
                    folders={categories as DragAndDropFolder[]}
                    onDragEnd={onCategoryDragEnd}
                    droppableId={'parentDroppableId'}
                  >
                    {categories.map((category, index) => (
                      <div key={index}>
                        <DraggableFolder
                          key={index}
                          grid={8}
                          folder={category as DragAndDropFolder}
                          index={index}
                          isSubFolder={false}
                          disableDragAndDrop={inputSearchValue.length > 0}
                          FolderTitleActions={
                            <div className="flex items-center gap-2">
                              {category.id !== 0 && (
                                <div className="flex flex-row items-center justify-end">
                                  {published(category) ? (
                                    <span className="rounded-full bg-emerald-100 px-2 py-0.5 text-xs text-emerald-600">
                                      Published
                                    </span>
                                  ) : (
                                    <span className="rounded-full bg-amber-100 px-2 py-0.5 text-xs text-amber-600">
                                      Draft
                                    </span>
                                  )}
                                  {category.isGlobal && (
                                    <Tooltip title={'Global Template'}>
                                      <GlobeAltIcon
                                        className={'ml-2 h-4 w-4 text-blue-400'}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              )}
                              <IconButton
                                disableRipple
                                className="p-0.5 hover:!bg-gray-300"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  event.preventDefault()
                                  setSelected(category)
                                  setSelectedLearnerCategoryId(category.id)
                                }}
                                data-testid={`edit-category-${category.name}`}
                              >
                                <Cog6ToothIcon className="h-6 w-6 text-gray-500" />
                              </IconButton>
                            </div>
                          }
                        >
                          <div className="bg-white">
                            <DragAndDropFolderList
                              folders={
                                category.learnerCourses as DragAndDropFolder[]
                              }
                              onDragEnd={onCourseDragEnd}
                              droppableId={`droppable-${category.id}`}
                            >
                              {category.learnerCourses?.map(
                                (course, courseIndex) => (
                                  <div key={`course-${course.id}`}>
                                    <Draggable
                                      key={course.id.toString()}
                                      draggableId={course.id.toString()}
                                      index={courseIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                          )}
                                        >
                                          <div
                                            tabIndex={0}
                                            onKeyDown={() => {}}
                                            onClick={() =>
                                              navigate(
                                                routes.editLearnerCourse({
                                                  id: course.id,
                                                }),
                                              )
                                            }
                                            role="button"
                                            className="flex cursor-pointer items-center gap-2 !border !border-gray-50 !border-b-gray-200 bg-white p-4 py-2 text-left hover:bg-gray-100"
                                          >
                                            <DragHandleComponent
                                              dragHandleProps={
                                                provided.dragHandleProps
                                              }
                                              disableDragAndDrop={false}
                                            />
                                            <span className="wrap line-clamp-1 inline-flex grow py-1">
                                              <div
                                                data-testid={`course-link-${course.id}`}
                                                className={
                                                  'line-clamp-1 grow text-sm text-gray-600'
                                                }
                                              >
                                                {course.name}
                                              </div>
                                            </span>

                                            <div className="flex flex-row justify-end">
                                              {published(course) ? (
                                                <span className="rounded-full bg-emerald-100 px-2 py-0.5 text-xs text-emerald-600">
                                                  Published
                                                </span>
                                              ) : (
                                                <span className="rounded-full bg-amber-100 px-2 py-0.5 text-xs text-amber-600">
                                                  Draft
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  </div>
                                ),
                              )}
                              {category.learnerCourses?.length === 0 && (
                                <div className="grow p-3 px-4">
                                  <p className="text-sm italic text-gray-500">
                                    Category is Empty
                                  </p>
                                </div>
                              )}
                              <div className="flex justify-center p-1">
                                <Button
                                  size="small"
                                  buttonDataTestId={`new-course-${category.name}`}
                                  onClick={() =>
                                    openNewCourseModal(category.id)
                                  }
                                  variant="outlined"
                                  className="min-w-[0] !border-dashed text-left text-sm"
                                  startIcon={<PlusIcon className="h-5 w-5" />}
                                >
                                  New Course
                                </Button>
                              </div>
                            </DragAndDropFolderList>
                          </div>
                        </DraggableFolder>
                      </div>
                    ))}
                  </DragAndDropFolderList>
                )}
              </div>
            </div>
          </main>
          <footer className="p-4">
            <div className="flex justify-center">
              <Button
                buttonDataTestId="learn-cat-add-new-category"
                onClick={() => setNewCategoryModalOpen(true)}
                endIcon={<PlusIcon className="h-4 w-4" />}
              >
                Add A New Category
              </Button>
            </div>
          </footer>
        </div>
        {selected ? (
          <div className="h-full w-full bg-white align-middle drop-shadow-md">
            <div className="flex h-full w-full justify-center">
              <div className="h-full w-full max-w-[900px] px-9 pt-9">
                <CategoryDetails
                  onCourseDragEnd={onCourseDragEnd}
                  learnerCategory={selected}
                  updateLearnerCategoryMutation={updateLearnerCategory}
                  deleteLearnerCategoryMutation={deleteLearnerCategory}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grow place-items-center p-1">
            <Empty title="Please select a category" />
          </div>
        )}
      </div>
      <Modal
        open={newCourseModalOpen}
        onClose={() => setNewCourseModalOpen(false)}
        title={'Add a new course'}
        className="w-full max-w-3xl"
      >
        <LearnerCourseForm
          learnerCourse={null}
          learnerCategoryId={selectedLearnerCategoryId}
          cancel={() => setNewCourseModalOpen(false)}
          onSave={saveNewCourse}
          error={error}
        />
      </Modal>
      <Modal
        open={newCategoryModalOpen}
        onClose={() => setNewCategoryModalOpen(false)}
        dialogClassName={'!p-0 max-w-[500px]'}
        title={'Add a new category'}
      >
        <LearnerNewCategoryForm save={saveNewCategory} />
      </Modal>
    </>
  )
}

export default LearnerCategories
