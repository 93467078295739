import React, { useEffect, useRef, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { TrashIcon } from '@heroicons/react/24/outline'
import { captureException } from '@sentry/browser'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import {
  ImproverGoalMember,
  LearnerMilestonesPerClient,
  TableName,
  type DeleteLearnerCourseMutation,
  type LearnerCategory,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import { Link, navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { apolloCache } from 'src/apolloCache'
import DragHandle from 'src/components/DragHandle/DragHandle'
import Empty from 'src/components/Empty/Empty'
import GoalTargetIcon from 'src/components/Goals/GoalTargetIcon/GoalTargetIcon'
import IconButton from 'src/components/IconButton/IconButton'
import Button from 'src/components/Library/Button/Button'
import { handleDragEnd } from 'src/components/Library/DragAndDropFolders/DragAndDropFolderList'
import { learnerCategoryConfig } from 'src/components/Library/RecordSettingsModal/config'
import RecordSettingsModal from 'src/components/Library/RecordSettingsModal/RecordSettingsModal'
import Modal from 'src/components/Modal/Modal'
import LearnerAssociatedMilestonesList from 'src/components/Settings/LearnerAssociatedMilestonesList/LearnerAssociatedMilestonesList'
import { QUERY as FIND_LEARNER_CATEGORIES_QUERY } from 'src/components/Settings/LearnerCategory/LearnerCategoriesCell'
import LearnerExistingMilestonesForm from 'src/components/Settings/LearnerExistingMilestonesForm/LearnerExistingMilestonesForm'
import Status from 'src/components/Status/Status'
import Tag from 'src/components/Tag/Tag'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import { DELETE_COURSE_MUTATION } from 'src/lib/queries/learner/learnerCourse'
import { useAuth } from 'src/Providers'
import { getDraggableItemStyle, published } from 'src/Util'

interface Props {
  learnerCategory: any
  onCourseDragEnd: any
  updateLearnerCategoryMutation: any
  deleteLearnerCategoryMutation: any
}

const CategoryDetails: React.FC<Props> = ({
  learnerCategory,
  onCourseDragEnd,
  updateLearnerCategoryMutation,
  deleteLearnerCategoryMutation,
}) => {
  const { hasRole } = useAuth()
  const confirm = useConfirm()
  const apolloClient = useApolloClient()

  const [selected, setSelected] = useState<any>()
  const [checked, setChecked] = useState(false)
  const settingsModal = useBoolean(false)
  const [delCourseModalOpen, setDelCourseModalOpen] = useState(false)
  const [delCatModalOpen, setDelCatModalOpen] = useState(false)
  const [milestonesModalContent, setMilestonesModalContent] = useState({
    id: null,
    milestones: [],
    type: null,
    perClientMilestones: [],
    tagAlongFunc: null,
  })
  const [associatedMilestonesModalOpen, setAssociatedMilestonesModalOpen] =
    useState(false)

  const [deleteLearnerCourse] = useMutation<DeleteLearnerCourseMutation>(
    DELETE_COURSE_MUTATION,
    {
      onCompleted: () => {
        toast.success('Course deleted')
        navigate(routes.settingsLearnerCategories())
      },
      onError: (error) => {
        toast.error(error.message)
      },
      update: (_, { data: { deleteLearnerCourse } }) => {
        try {
          const existingData = apolloCache.readQuery<{
            learnerCategories: LearnerCategory[]
          }>({
            query: FIND_LEARNER_CATEGORIES_QUERY,
          })

          const categoryId = deleteLearnerCourse.learnerCategoryId

          const updatedCategory = existingData.learnerCategories.find(
            (category) => category.id === categoryId,
          )

          const updatedCourses = updatedCategory.learnerCourses.filter(
            (course) => course.id !== deleteLearnerCourse.id,
          )

          const updatedCategories = existingData.learnerCategories.map(
            (category) => {
              if (category.id === categoryId) {
                return {
                  ...category,
                  learnerCourses: updatedCourses,
                }
              }
              return category
            },
          )

          apolloCache.writeQuery({
            query: FIND_LEARNER_CATEGORIES_QUERY,
            data: {
              learnerCategories: updatedCategories,
            },
          })
        } catch (error) {
          captureException(error, {
            extra: {
              message:
                'unable to update cache after deleteLearnerCourse mutation. Trying a hard refetch now instead.',
            },
          })
          apolloClient.refetchQueries({
            include: [FIND_LEARNER_CATEGORIES_QUERY],
          })
        }
      },
    },
  )

  useEffect(() => {
    setSelected(learnerCategory)
    if (learnerCategory) {
      setChecked(published(learnerCategory))
      const memberships = []
      learnerCategory?.membershipGroups?.forEach((item) =>
        memberships.push(item.membershipGroup.name),
      )
    }
  }, [learnerCategory])

  const useDraggableInPortal = () => {
    const self: any = useRef({}).current

    useEffect(() => {
      const div = document.createElement('div')
      div.style.position = 'absolute'
      div.style.pointerEvents = 'none'
      div.style.top = '0'
      div.style.width = '100%'
      div.style.height = '100%'
      self.elt = div
      document.body.appendChild(div)
      return () => {
        document.body.removeChild(div)
      }
    }, [self])

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args)
        if (provided.draggableProps.style.position === 'fixed') {
          return createPortal(element, self.elt)
        }
        return element
      }
  }

  const onDeleteCourse = (
    id: number,
    name: string,
    milestones: ImproverGoalMember[] = [],
    perClientMilestones: LearnerMilestonesPerClient[] = [],
  ) => {
    //model control here
    if (milestones.length > 0 || perClientMilestones.length > 0) {
      setMilestonesModalContent({
        id,
        milestones,
        type: 'course',
        perClientMilestones,
        tagAlongFunc: null,
      })
      setDelCourseModalOpen(true)
    } else {
      confirm({
        title: `Delete ${name}?`,
        description: 'Are you sure you want to delete this course?',
      }).then((isConfirmed) => {
        if (!isConfirmed) return
        deleteLearnerCourse({ variables: { id } })
      })
    }
  }

  const onViewAssociatedMilestones = (
    id: number,
    type: string,
    milestones: ImproverGoalMember[] = [],
    perClientMilestones: LearnerMilestonesPerClient[] = [],
  ) => {
    setMilestonesModalContent({
      id,
      milestones,
      type,
      perClientMilestones,
      tagAlongFunc: null,
    })
    setAssociatedMilestonesModalOpen(true)
  }

  const renderDraggable = useDraggableInPortal()

  const isSharedWithClients =
    learnerCategory?.isGlobal ||
    learnerCategory?.aclPrincipals.find(
      ({ principalType }) => principalType === 'CLIENT',
    )

  const categoryMilestones =
    learnerCategory?.learnerCourses.flatMap(
      (learnerCourse) => learnerCourse.associatedMilestones,
    ) || []

  //tally together all learnermilestonesperclient for each learnercourse so that they can be displayed in the modal for each category has how many for each client
  const condensedCategoryClientMilestones = learnerCategory?.learnerCourses
    .flatMap((learnerCourse) => learnerCourse.learnerMilestonesPerClient)
    .reduce((acc, curr) => {
      if (!curr) return acc
      const found = acc.find((item) => item.clientName === curr.clientName)
      if (found) {
        found.totalMilestones += curr.totalMilestones
      } else {
        acc.push({
          clientName: curr.clientName,
          totalMilestones: curr.totalMilestones,
        })
      }
      return acc
    }, [])

  if (!selected) {
    return (
      <div className="flex h-full">
        <div className="m-auto">
          <Empty
            learnerCourse
            title="No category"
            description="Please select a category"
          />
        </div>
      </div>
    )
  }
  return (
    <div className="flex h-full flex-col">
      <style>
        {`
        .e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
              border-bottom: none;
          }
        .e-inplaceeditor .e-editable-value-wrapper {
              background: #f3f4f6 !important;
              border-radius: 4px !important;
            }
        .e-inplaceeditor .e-editable-value-wrapper .e-editable-overlay-icon {
          visibility: visible !important;
            }
        `}
      </style>
      <div className="border-b border-gray-200 bg-white py-5 sm:flex-wrap">
        <div className="flex items-center justify-between">
          <div className="flex justify-start">
            <h3 className="pr-4 text-lg font-medium leading-6 text-gray-900">
              {learnerCategory?.name}
            </h3>
          </div>
          <div className="flex flex-row items-center justify-end">
            {(categoryMilestones?.length > 0 ||
              condensedCategoryClientMilestones?.length > 0) && (
              <IconButton
                className="mr-3 h-5 w-5"
                danger
                onClick={() =>
                  onViewAssociatedMilestones(
                    selected.id,
                    'category',
                    categoryMilestones,
                    condensedCategoryClientMilestones,
                  )
                }
              >
                <GoalTargetIcon sizeInPixels={24} />
              </IconButton>
            )}
            <Status published={checked} />
            <Button
              onClick={() => settingsModal.setTrue()}
              buttonDataTestId="learner-category-settings-button"
            >
              Settings
            </Button>
          </div>
        </div>
      </div>
      <div className="py-3">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-xs font-medium uppercase tracking-wide text-gray-700">
              Courses
            </h3>
          </div>
        </div>
      </div>
      <div className="sl-drag-parent overflow-y-auto pb-12">
        {learnerCategory?.learnerCourses?.length === 0 && (
          <p className="text-sm text-gray-400">No Courses</p>
        )}
        <DragDropContext
          onDragEnd={(result) =>
            handleDragEnd(
              result,
              learnerCategory.learnerCourses,
              onCourseDragEnd,
            )
          }
        >
          <Droppable droppableId={0 + ''}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {learnerCategory?.learnerCourses?.map(
                  (learnerCourse, courseIndex) => {
                    const courseMilestones =
                      learnerCourse.associatedMilestones || []
                    const courseClientMilestones =
                      learnerCourse.learnerMilestonesPerClient || []
                    return (
                      <Draggable
                        key={learnerCourse.id}
                        draggableId={learnerCourse.id + ''}
                        index={courseIndex}
                      >
                        {renderDraggable((provided, snapshot) => (
                          <div
                            // className="divide-y divide-gray-20 px-3"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getDraggableItemStyle(
                              provided.draggableProps.style,
                            )}
                            className={
                              snapshot.isDragging
                                ? 'sl-drag-active mb-1.5'
                                : 'sl-drag-default mb-1.5'
                            }
                          >
                            <div className="">
                              <div
                                tabIndex={0}
                                onKeyDown={() => {}}
                                role="button"
                                className="group flex items-center justify-between space-x-2 rounded-md bg-gray-50 py-2.5 hover:bg-gray-200"
                                onClick={() =>
                                  navigate(
                                    routes.editLearnerCourse({
                                      id: learnerCourse.id,
                                    }),
                                  )
                                }
                              >
                                <span className="wrap d-flex inline-flex items-center">
                                  <div {...provided.dragHandleProps}>
                                    <DragHandle
                                      className="sl-drag-icon"
                                      size={15}
                                    />
                                  </div>
                                  <Link
                                    to={routes.editLearnerCourse({
                                      id: learnerCourse.id,
                                    })}
                                    className={
                                      snapshot.isDragging &&
                                      !snapshot.draggingOver
                                        ? 'cursor-no-drop text-red-600 line-through'
                                        : 'text-sm font-normal text-gray-800'
                                    }
                                    data-testid={`category-details-course-navigation-${learnerCourse.id}`}
                                  >
                                    {learnerCourse.name}
                                  </Link>
                                </span>
                                <div className="flex flex-row items-center justify-end">
                                  <IconButton
                                    className="sl-drag-icon"
                                    danger
                                    color="gray-500"
                                    size="5"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      onDeleteCourse(
                                        learnerCourse.id,
                                        learnerCourse.name,
                                        courseMilestones,
                                        courseClientMilestones,
                                      )
                                    }}
                                  >
                                    <TrashIcon className="h-5 w-5 text-red-500" />
                                  </IconButton>

                                  {(courseMilestones?.length > 0 ||
                                    courseClientMilestones?.length > 0) && (
                                    <IconButton
                                      className="h-5 w-5"
                                      danger
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        onViewAssociatedMilestones(
                                          learnerCourse.id,
                                          'course',
                                          courseMilestones,
                                          courseClientMilestones,
                                        )
                                      }}
                                    >
                                      <GoalTargetIcon sizeInPixels={24} />
                                    </IconButton>
                                  )}

                                  <Tag
                                    className={`${
                                      published(learnerCourse)
                                        ? 'text-green-400'
                                        : 'text-orange-500'
                                    } h-3 w-3`}
                                    // children='Published'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Draggable>
                    )
                  },
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {settingsModal.value && (
        <RecordSettingsModal
          modalVisible={settingsModal}
          record={{
            id: learnerCategory.id,
            type: 'LearnerCategory' as TableName,
            name: learnerCategory.name,
            status: learnerCategory.status,
            isGlobal: learnerCategory.isGlobal,
          }}
          principalTypes={
            hasRole(['SUPERADMIN', 'STAFFLINK'])
              ? ['MEMBERSHIP', 'MEMBERSHIPGROUP', 'CLIENT']
              : ['MEMBERSHIP', 'MEMBERSHIPGROUP']
          }
          config={learnerCategoryConfig}
          deleteConfirmOverride={
            categoryMilestones.length > 0 ||
            condensedCategoryClientMilestones.length > 0
              ? ({ afterwards }) => {
                  setMilestonesModalContent({
                    id: selected.id,
                    milestones: categoryMilestones,
                    type: 'category',
                    perClientMilestones: condensedCategoryClientMilestones,
                    tagAlongFunc: afterwards,
                  })
                  setDelCatModalOpen(true)
                }
              : undefined
          }
          onDelete={
            (isSharedWithClients && hasRole(['SUPERADMIN'])) ||
            !isSharedWithClients
              ? () =>
                  deleteLearnerCategoryMutation({
                    variables: {
                      id: learnerCategory.id,
                    },
                    onCompleted: async () => {
                      setSelected(null)
                      settingsModal.setFalse()
                    },
                  })
              : null
          }
          onSave={(record) => {
            updateLearnerCategoryMutation({
              variables: {
                id: learnerCategory.id,
                input: {
                  name: record.name,
                  status: record.status,
                  isGlobal: record.isGlobal,
                },
              },
            })
          }}
        />
      )}
      <Modal
        open={delCourseModalOpen}
        onClose={() => setDelCourseModalOpen(false)}
        dialogClassName={'!p-0 max-w-[500px]'}
        title={'Delete Course'}
        disablePortal={false}
      >
        <LearnerExistingMilestonesForm
          milestones={milestonesModalContent.milestones}
          perClientMilestones={milestonesModalContent.perClientMilestones}
          learnerCourses={learnerCategory.learnerCourses}
          type="course"
          onDelete={() => {
            milestonesModalContent.id &&
              deleteLearnerCourse({
                variables: { id: milestonesModalContent.id },
              })
          }}
          onClose={() => setDelCourseModalOpen(false)}
        />
      </Modal>
      <Modal
        open={delCatModalOpen}
        onClose={() => setDelCatModalOpen(false)}
        dialogClassName={'!p-0 max-w-[500px]'}
        title={'Delete Category'}
        disablePortal={false}
      >
        <LearnerExistingMilestonesForm
          milestones={milestonesModalContent.milestones}
          perClientMilestones={milestonesModalContent.perClientMilestones}
          learnerCourses={learnerCategory.learnerCourses}
          type="category"
          onDelete={() => {
            milestonesModalContent.tagAlongFunc &&
              milestonesModalContent
                .tagAlongFunc()
                .then(() => setSelected(null))
                .catch((error) => captureException(error))
          }}
          onClose={() => setDelCatModalOpen(false)}
        />
      </Modal>
      <Modal
        open={associatedMilestonesModalOpen}
        onClose={() => setAssociatedMilestonesModalOpen(false)}
        dialogClassName={'!p-0 max-w-[500px]'}
        title={'Associated Milestones'}
        disablePortal={false}
      >
        <LearnerAssociatedMilestonesList
          milestones={milestonesModalContent.milestones}
          type={milestonesModalContent.type}
          perClientMilestones={milestonesModalContent.perClientMilestones}
          learnerCourses={learnerCategory.learnerCourses}
        />
      </Modal>
    </div>
  )
}

export default CategoryDetails
