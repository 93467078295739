import { useEffect, useState } from 'react'

import { MenuItem, Select } from '@mui/material'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindLearnerCategoriesSelectQuery {
    learnerCategories: learnerCategoriesByClientAsAdmin {
      id
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({
  learnerCategories,
  learnerCategoryId,
  setSelectedCategory,
}) => {
  return (
    <Select
      name="learnerCourseId"
      value={learnerCategoryId}
      onChange={(event) => {
        const selectedCategory = event.target.value

        setSelectedCategory(selectedCategory)
      }}
      size="small"
      fullWidth
      required
    >
      {learnerCategories?.map((category) => (
        <MenuItem key={category.id} value={category.id}>
          {category?.name}
        </MenuItem>
      ))}
    </Select>
  )
}
